import ApiService from "@/core/services/api.service.js";

const internalLinkService = {
    get(id) {
        return  new Promise((resolve, reject) => {
          ApiService.get("api/admin/category/internal/link/detail/"+id)
            .then(function(response) {
              if (response.data) {
                resolve(response.data.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
   
    create(id,data) {
        return  new Promise((resolve, reject) => {
          ApiService.post("api/admin/category/internal/link/generate/"+id,data)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },

  

}

export default internalLinkService;